// @flow

import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components'

import { useFormik } from 'formik'

import { togglePageDialog, handleRequestGetUserAccount, handleRequestUpdateUserAccount } from 'actions'

import { OutlineButton, Container, Dialog, PageTitle, Alert, ALERT_TYPE, Text, Tooltip, Button } from 'components/ReUsable'

import Form from 'components/ReUsable/Form'
import FormErrors from 'components/ReUsable/FormErrors'

import DeleteAccountDialogContent from './deleteAccountDialogContent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccessToken } from './accessToken'
import { NEW_API_ACTIVE } from 'featureToggles'

import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'
import { MultifactorAuthentication } from './multifactorAuthentication'

const EditButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--color-primary);
  margin-left: auto !important;
`

const ContentDiv = styled.div`
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-primary);
  width: 945px;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledMain = styled.main`
  display: block;
`

const InputDummy = styled.div<{ disabled?: boolean; isEditing?: boolean }>`
  background-color: ${(props: any) => (props.disabled ? '#e9ecef' : '')};
  height: 3rem;
  @media (prefers-color-scheme: dark) {
    background-color: ${(props: any) => (props.disabled ? 'var(--color-disabled)' : '')};
    opacity: ${(props: any) => (props.disabled ? '0.8' : '')};
  }
`

const HiddableButton = styled(Button)<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  border-color: var(--color-primary);
  &:hover {
    color: var(--color-lightest);
    background: var(--color-primary);
    border-color: var(--color-primary);
  }
`

const StyledRequiredText = styled(Text)`
  font-size: 13px;
  color: var(--text-muted);
`

const StyledEditAccountSpan = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  bottom: 2px;
  img {
    width: 24px;
    height: auto;
  }
`

type Props = {
  dialog: {
    isDialogOpen: boolean
    isPageDialogOpen: boolean
    dialogId: string | null
  }
  userAccount: {
    user: {
      address: string
      company: string
      country: string
      email: string
      family_name: string
      given_name: string
      phone: string
      postcode: string
      website: string
    }
    updateUserAccountAction: {
      updateSuccess: boolean
      updateErrorMessage: string
    }
    updateUserAccountActionPassword: {
      updateSuccess: boolean
    }
    deleteUserAccountAction: {
      deletionSuccess: boolean
      deletionErrorMessage: string
    }
  }
  togglePageDialog: (obj?: any) => void
  handleRequestGetUserAccount: () => void
  handleRequestUpdateUserAccount: (payload: any) => void
}

export function UserAccount(props: Props) {
  const { currentUser } = useKeycloakAuth()
  const resetPasswordUrl = `${currentUser?.iss}/protocol/openid-connect/auth?kc_action=UPDATE_PASSWORD&client_id=${currentUser?.aud}&redirect_uri=${window.location.origin}/user_account&response_type=code`

  const [isEditing, setIsEditing] = useState(false)
  const { isDialogOpen, isPageDialogOpen } = props.dialog
  const { user, updateUserAccountAction, updateUserAccountActionPassword } = props.userAccount
  const handleRequestGetUserAccount = props.handleRequestGetUserAccount

  useEffect(() => {
    handleRequestGetUserAccount()
  }, [isEditing, handleRequestGetUserAccount])

  useEffect(() => {
    if (updateUserAccountAction.updateSuccess) {
      setIsEditing(false)
    }
  }, [updateUserAccountAction.updateSuccess])

  useEffect(() => {
    formik.setFieldValue('given_name', user.given_name)
    formik.setFieldValue('family_name', user.family_name)
    formik.setFieldValue('company', user.company)
    formik.setFieldValue('phone', user.phone)
    formik.setFieldValue('address', user.address)
    formik.setFieldValue('postcode', user.postcode)
    formik.setFieldValue('country', user.country)
    formik.setFieldValue('website', user.website)
    formik.unregisterField('email')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmitWithValidation = (values: any, { setErrors, _ }: any) => {
    const errors: { given_name?: string; family_name?: string } = {}

    if (values.given_name.length === 0) {
      errors.given_name = 'First name is required'
    }

    if (values.family_name.length === 0) {
      errors.family_name = 'Last name is required'
    }

    if (Object.entries(errors).length > 0) {
      setErrors(errors)
      return
    }

    return props.handleRequestUpdateUserAccount({ ...values })
  }

  const initialUser: Partial<typeof user> = Object.assign({}, user)
  delete initialUser.email

  const formik = useFormik({
    initialValues: initialUser,
    onSubmit: onSubmitWithValidation
  })

  const handleResetPassword = () => {
    if (!currentUser) return
    window.location.href = resetPasswordUrl
  }
  return (
    <StyledMain role="main" className={`${isDialogOpen ? 'blur-element' : ''}`}>
      <Container>
        <ContentDiv data-cy="user-account-content">
          <EditButton className="ml-auto mb-5" onClick={() => setIsEditing(!isEditing)}>
            {(!isEditing && (
              <StyledEditAccountSpan>
                <img alt="Edit account" src="/imgs/manage.svg" />
                Edit account
              </StyledEditAccountSpan>
            )) || (
              <span>
                <FontAwesomeIcon icon={['fas', 'times']} /> Cancel
              </span>
            )}
          </EditButton>
          <FormErrors errors={formik.errors} errorMessage={updateUserAccountAction.updateErrorMessage} />
          {updateUserAccountActionPassword.updateSuccess && <Alert type={ALERT_TYPE.success} message="Password reset email has been sent." />}
          <Form handleSubmit={formik.handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <PageTitle className="mr-auto mb-3">Account</PageTitle>
                </div>
                {isEditing && (
                  <div className="col-12">
                    <StyledRequiredText>All fields marked with * are required.</StyledRequiredText>
                  </div>
                )}
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">
                      First name<sup>*</sup>
                    </div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="given_name" onChange={formik.handleChange} value={formik.values.given_name} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">
                      Last name<sup>*</sup>
                    </div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="family_name" onChange={formik.handleChange} value={formik.values.family_name} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">
                      Email<sup>*</sup>
                    </div>
                    <div className="col">
                      <InputDummy className="form-control d-flex justify-content-between align-items-center" disabled isEditing={isEditing}>
                        <span id="email" className="mt-1">
                          {user.email}
                        </span>
                        <EditButton className={`ml-auto ${!isEditing ? 'd-none' : 'd-block'}`} type="button">
                          <Tooltip message="For security reasons, the email address cannot be changed.">
                            <FontAwesomeIcon icon={['fas', 'question-circle']} />
                          </Tooltip>
                        </EditButton>
                      </InputDummy>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">
                      Password<sup>*</sup>
                    </div>
                    <div className="col">
                      <InputDummy className="form-control d-flex justify-content-between align-items-center" disabled isEditing={isEditing}>
                        <span className="mt-2">**********</span>
                        <EditButton id="reset-password" className={`ml-auto ${!isEditing ? 'd-none' : 'd-block'}`} type="button" onClick={handleResetPassword}>
                          <FontAwesomeIcon icon={['fas', 'edit']} />
                        </EditButton>
                      </InputDummy>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">Company</div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="company" onChange={formik.handleChange} value={formik.values.company} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">Phone</div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="phone" onChange={formik.handleChange} value={formik.values.phone} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">Street address and number</div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="address" onChange={formik.handleChange} value={formik.values.address} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">Postcode</div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="postcode" onChange={formik.handleChange} value={formik.values.postcode} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">Country</div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="country" onChange={formik.handleChange} value={formik.values.country} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-3">Website</div>
                    <div className="col">
                      <input type="text" className="form-control form-input" id="website" onChange={formik.handleChange} value={formik.values.website} disabled={!isEditing} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-auto">
                  <HiddableButton type="submit" hidden={!isEditing} className="btn-add-device">
                    SAVE CHANGES
                  </HiddableButton>
                  {isEditing && (
                    <OutlineButton
                      onClick={() => {
                        props.togglePageDialog({ dialogId: 'delete-account-dialog' })
                      }}
                    >
                      Delete Account
                    </OutlineButton>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </ContentDiv>
      </Container>
      <Dialog id="delete-account-dialog" title={'Delete user account'} show={isPageDialogOpen && props.dialog?.dialogId === 'delete-account-dialog'} onClose={() => props.togglePageDialog()}>
        <DeleteAccountDialogContent />
      </Dialog>
      <MultifactorAuthentication />
      {NEW_API_ACTIVE ? <AccessToken /> : null}
    </StyledMain>
  )
}

const mapState = (state) => ({
  dialog: state.dialog,
  userAccount: state.userAccount
})

const mapDispatch = {
  togglePageDialog,
  handleRequestGetUserAccount,
  handleRequestUpdateUserAccount
}

export default connect(mapState, mapDispatch)(UserAccount)
