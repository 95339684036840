// useAuth.ts
import { useAuth as useOIDCAuth } from 'react-oidc-context';
import { integrationTestRunning } from 'utils/helpers';
import { PERMISSIONS_ALL } from 'utils/mockPermissions';

const MOCK_AUTH = {
  currentUser: {
      "exp": 1730189874,
      "iat": 1730189574,
      "auth_time": 1730189574,
      "jti": "58a335ed-592b-4e3d-83b7-cd07a954cb61",
      "iss": "http://localhost:7070/realms/efoy-cloud-dev-realm",
      "aud": "http://localhost:8080",
      "sub": "f8e644e3-b320-428b-b974-ec12470fab67",
      "typ": "Bearer",
      "azp": "local-frontend",
      "sid": "f9253c98-6f16-4a66-9d8d-3072cd514680",
      "acr": "1",
      "scope": "openid email",
      "email_verified": true,
      "permissions": PERMISSIONS_ALL,
      "email": "demo-user@sfc.com"
  },
  getTokenSilently: () => Promise<null>,
  isAuthenticated: true,
  loading: false,
  login: () => null,
  logout: () => null,
  accessToken: '',
  loginWithRedirect: () => null
}

export function useKeycloakAuth() {
  if(integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return MOCK_AUTH;
  }
  
  const auth = useOIDCAuth();

  return {
    login: auth?.signinRedirect,
    logout: () => auth?.signoutRedirect({ post_logout_redirect_uri: window.location.origin }),
    isAuthenticated: auth?.isAuthenticated,
    currentUser: auth?.user?.profile,
    accessToken: auth?.user?.access_token,
    loading: auth?.isLoading,
    loginWithRedirect: auth?.signinRedirect,
  };
}
